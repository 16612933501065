import React from "react";
import "./App.css";

// import Router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import Component
import Header from "./component/Header";
import Footer from "./component/Footer";
// import ScrollToTop from "./component/ScrollToTop";

// import Pages
import Home from "./Pages/Home";
import TheCompany from "./Pages/TheCompany";
import Career from "./Pages/Career";
import Equipments from "./Pages/Equipments";
import Contact from "./Pages/Contact";
import ScrollToTopButton from "./component/ScrollToTopButton";
import ScrollToTop from "./component/ScrollToTop";

function App() {
  return (
    <React.StrictMode>
      <Router>
        <Header />
        <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/the-company" component={TheCompany} />
            <Route exact path="/equipments" component={Equipments} />
            <Route exact path="/career" component={Career} />
            <Route exact path="/contact-us" component={Contact} />
          </Switch>
          <ScrollToTopButton />
        <Footer />
      </Router>
    </React.StrictMode>
  );
}

export default App;
