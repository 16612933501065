import React, { useState } from "react";
import Swal from "sweetalert2";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({}); // State to store validation errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && !/^[A-Za-z\s]+$/.test(value)) {
      // If the value contains numeric characters, don't update the state
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Reset previous validation errors
      setErrors({});

      // Validate form data
      if (!formData.name || !formData.email || !formData.message) {
        // If any required field is missing, set validation error
        setErrors({ message: "All fields are required." });
        return;
      }

      // Define the API URL
      const apiUrl = "http://127.0.0.1:8000/api/contact";

      // Send a POST request to the API with the form data
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // If the response status is OK (200), the data was successfully submitted
        // Display a success message using Swal
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data submitted successfully!",
        });

        // Optionally, you can reset the form fields here
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        // Handle other errors or display error messages using Swal
        console.error("Error submitting data");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There was an error submitting the form!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle network errors using Swal
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "A network error occurred while submitting the form!",
      });
    }
  };

  return (
    <main id="main">
      <section id="call-to-action" className="call-to-action">
        <img src="assets/img/contact2.jpg" alt="Contact" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="text-center">
                <h3>Contact Us</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="career" className="contact">
        <div className="container section-title">
          <h2>Get In Touch</h2>
        </div>
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6">
              <div className="reply-form">
                <form
                  action="/api/contact" // Specify the correct API endpoint
                  method="post"
                  className="php-email-form"
                  data-aos-delay="200"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="name">Your Name</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Your Name*"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="email">Your Email</label>
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Your Email*"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-12 form-group mb-4">
                      <label htmlFor="message">Your Message</label>
                      <textarea
                        id="message"
                        name="message"
                        className="form-control"
                        rows="4"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-12 text-end">
                      <button type="submit" className="btn btn-primary shadow">
                        Send message
                      </button>
                    </div>
                    {errors.message && (
                      <div className="alert alert-danger">{errors.message}</div>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="col-lg-6 features-img-bg">
              <h3 className="mb-4">Have a project? Let's talk.</h3>
              <p>
                <i className="bi bi-geo-alt red-color"></i> E 1/127 PRIVATE
                SECTOR, ARERA COLONY, BHOPAL, MP 462016 INDIA
              </p>
              <p>
                <b>
                  <i className="bi bi-telephone red-color"></i>
                </b>{" "}
                +91 6232933394
              </p>
              <p>
                <b>
                  <i className="bi bi-envelope red-color"></i>
                </b>{" "}
                Ho.rhbpl@ramrajtech.com
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Contact;
