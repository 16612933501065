import { NavLink } from 'react-router-dom';
import { useState } from 'react';

function Header() {
  const [activeLink, setActiveLink] = useState('home');

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <NavLink to="/" className="logo d-flex align-items-center me-auto me-xl-0">
          <img src="assets/img/RHBPL.png" alt="RHBPL" />
        </NavLink>
        
        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                activeClassName={activeLink === 'home' ? 'active' : ''}
                onClick={() => handleNavLinkClick('home')}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/the-company"
                activeClassName={activeLink === 'the-company' ? 'active' : ''}
                onClick={() => handleNavLinkClick('the-company')}
              >
                The Company
              </NavLink>
            </li>
            <li>
          <a href="/#services">Services</a>
        </li>
        <li>
          <a href="/#projects">Projects</a>
        </li>
        <li>
          <a href="/#clients">Our Clients</a>
        </li>
        <li>
          <NavLink to="/equipments" activeClassName="active">
            Equipments
          </NavLink>
        </li>
        <li>
          <NavLink to="/career" activeClassName="active">
            Career
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact-us" activeClassName="active">
            Contact Us
          </NavLink>
        </li>
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>
        <span className="btn-getstarted mob-email shadow">+91 6232933394 | Ho.rhbpl@ramrajtech.com</span>
      </div>
    </header>
  );
}

export default Header;
