import React, { useState, useEffect } from 'react';

const Footer = () => {
  const [showButton, setShowButton] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Function to handle scroll events and toggle button visibility
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const halfPageHeight = window.innerHeight / 2;
  
    if (scrollY > halfPageHeight) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.StrictMode>
        <footer id="footer" className="footer">
    <div className="container footer-top">
      <div className="row gy-4">
        <div className="col-lg-3 col-6 footer-links">
          <h4>Services</h4>
          <ul>
            <li><a href="#">Buildings</a></li>
            <li><a href="#">Bridges</a></li>
            <li><a href="#">Highways</a></li>
          </ul>
        </div>

        <div className="col-lg-3 col-6 footer-links">
          <h4>Additional links</h4>
          <ul>
            <li><a href="#">The Company</a></li>
            <li><a href="#">Equipments</a></li>
            <li><a href="#">Projects</a></li>
            <li><a href="#">Career</a></li>
          </ul>
        </div>

        <div className="col-lg-3 col-md-6 footer-contact text-center text-md-start">
          <h4>Contact Details</h4>
          <p className="mt-4"><strong>Phone:</strong> <span>+91 6232933394</span></p>
          <p><strong>Email:</strong> <span>Ho.rhbpl@ramrajtech.com</span></p>
          <div className="social-links d-flex mt-4">
            <a href="http://ramrajbuildcon.com" target="_blank"><i className="bi bi-twitter"></i></a>
            <a href="http://ramrajbuildcon.com" target="_blank"><i className="bi bi-google"></i></a>
            <a href="http://ramrajbuildcon.com" target="_blank"><i className="bi bi-facebook"></i></a>           
          </div>
        </div>

        <div className="col-lg-3 col-md-6 footer-about">
          <h4>Write To RHBPL</h4>
          <form action="#" className="sign-up-form d-flex">
            <input type="text" className="form-control border-0" placeholder="Enter your e-mail" />
            <input type="submit" className="btn btn-primary red-bg" value="Subscribe" />
          </form>
        </div>
      </div>
    </div>

    <div className="container copyright text-center mt-4">
      <p>&copy; <span>Copyright</span> <strong className="px-1">Ramraj Hitech Buildcon</strong> <span>All Rights Reserved</span></p>
      <div className="credits">
        Designed by <a href="http://www.ramrajtech.com" target="_blank">Ramraj Tech</a>
      </div>
    </div>
  </footer>
    </React.StrictMode>
  );
}

export default Footer;
