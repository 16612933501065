import React, { useState } from "react";
import Swal from "sweetalert2";

const Career = () => {
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    address: "",
    state: "",
    city: "",
    zipCode: "",
    mobile: "",
    email: "",
    position: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length > 0) {
      // Show validation errors in a Swal popup
      const validationErrorMessages = Object.values(validationErrors).join("<br>");
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        html: validationErrorMessages,
      });
      return;
    }

    try {
      // Define the URL of your Laravel API endpoint
      const apiUrl = "http://127.0.0.1:8000/api/career-opportunities";

      // Create an object to send in the POST request
      const requestBody = {
        ...formData,
      };

      // Make the HTTP POST request to your API
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Clear the form by resetting the formData state
        setFormData({
          name: "",
          lastName: "",
          address: "",
          state: "",
          city: "",
          zipCode: "",
          mobile: "",
          email: "",
          position: "",
        });

        // Show a success message using Swal
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your application has been submitted successfully!",
        });
      } else {
        // Handle errors or display error messages
        console.error("Error submitting data");

        // Show an error message using Swal
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "An error occurred while submitting your application. Please try again later.",
        });
      }
    } catch (error) {
      console.error("Error:", error);

      // Show an error message using Swal
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          "An error occurred while submitting your application. Please try again later.",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name.trim()) {
      errors.name = "Name is required";
    }

    if (!data.address.trim()) {
      errors.address = "Address is required";
    }

    if (!data.state.trim()) {
      errors.state = "State is required";
    }

    if (!data.mobile.trim()) {
      errors.mobile = "Mobile is required";
    }

    if (!data.email.trim()) {
      errors.email = "Email is required";
    }

    if (!data.position.trim()) {
      errors.position = "Position is required";
    }

    return errors;
  };

  return (
    <React.StrictMode>
      <main id="main">
        <section id="call-to-action" className="call-to-action">
          <img src="assets/img/career3.jpg" alt="" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="text-center">
                  <h3>Career</h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="career" className="contact">
          <div className="container section-title">
            <h2>Grow with Us</h2>
            <p>
              Please fill out all the required fields below:
            </p>
          </div>
          {/* Display validation errors */}
          {/* Other form fields... */}
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="reply-form">
                  <form
                    action="http://127.0.0.1:8000/api/career-opportunities"
                    method="post"
                    className="php-email-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-6 form-group mb-3">
                        <label>
                          Your Name <span className="required">*</span>{" "}
                        </label>
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Your Name*"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label>
                          Last Name <span className="required">*</span>{" "}
                        </label>
                        <input
                          name="lastName"
                          type="text"
                          className="form-control"
                          placeholder="Your Last Name*"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-12 form-group mb-3">
                        <label>Address</label>
                        <textarea
                          name="address"
                          className="form-control"
                          rows="1"
                          placeholder="Address"
                          value={formData.address}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label>
                          State <span className="required">*</span>{" "}
                        </label>
                        <input
                          name="state"
                          type="text"
                          className="form-control"
                          placeholder="Your State*"
                          value={formData.state}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label>
                          City <span className="required">*</span>{" "}
                        </label>
                        <input
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="Your City*"
                          value={formData.city}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label>
                          Zip Code <span className="required">*</span>
                        </label>
                        <input
                          name="zipCode"
                          type="text"
                          className="form-control"
                          placeholder="Zip Code*"
                          value={formData.zipCode}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label>
                          Mobile <span className="required">*</span>
                        </label>
                        <input
                          name="mobile"
                          type="text"
                          className="form-control"
                          placeholder="Your Mobile*"
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label>
                          Your Email <span className="required">*</span>{" "}
                        </label>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Your Email*"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label>Position Applying For</label>
                        <input
                          name="position"
                          type="text"
                          className="form-control"
                          placeholder="Position Applying*"
                          value={formData.position}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-12 text-end">
                        <button
                          type="submit"
                          className="btn btn-primary shadow"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-lg-6 d-flex align-items-center features-img-bg">
                <img
                  src="assets/img/career4.jpg"
                  className="img-fluid"
                  alt="Career Ramraj Hitech Buildcon"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.StrictMode>
  );
};

export default Career;
