import React, { useState } from "react";
import Modal from 'react-modal';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

function Equipments(props) {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const images = [
    "assets/img/equipments/CM20130806-01062-38425.jpg",
    "assets/img/equipments/Dozer.jpg",
    "assets/img/equipments/Electronic-Sensor.jpg",
    "assets/img/equipments/Excavator-Backhoe-loader.jpg",
    "assets/img/equipments/Front-And-Loader.jpg",
    "assets/img/equipments/Hot-Mixer-Plant.jpg",
    "assets/img/equipments/Hydraulic-Broomer.jpg",
    "assets/img/equipments/Hydraulic-Excavator.jpg",
    "assets/img/equipments/komatsu-rock-breakers.jpg",
    "assets/img/equipments/Mix-Plant.jpg",
    "assets/img/equipments/Motor-grader.jpg",
    // Add more image URLs
  ];

  const openLightbox = (index) => {
    setLightboxOpen(true);
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <main id="main">
      <section id="call-to-action" className="call-to-action">
                <img src="assets/img/career3.jpg" alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="text-center">
                                <h3>Equipments</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

      <section id="portfolio" className="portfolio">
      <div className="container section-title">
                    <h2>Portfolio</h2>
                    <p>Ramraj Hightech Buildcon Portfolio</p>
                </div>

        <div className="container">
          <div className="row gy-4 isotope-container">
            {images.map((imageUrl, index) => (
              <div key={index} className="col-lg-3 col-md-6 portfolio-item isotope-item filter-app">
                <img
                  src={imageUrl}
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-info">
                  <h4>Image Title</h4>
                  <a
                    href="#"
                    onClick={() => openLightbox(index)}
                    className="glightbox preview-link"
                  >
                    <i className="bi bi-zoom-in"></i>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Modal
        isOpen={lightboxOpen}
        onRequestClose={closeLightbox}
        contentLabel="Lightbox"
        className="lightbox-modal"
      >
        <Lightbox
          mainSrc={images[lightboxIndex]}
          nextSrc={images[(lightboxIndex + 1) % images.length]}
          prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setLightboxIndex((lightboxIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
        />
      </Modal>
    </main>
  );
}

export default Equipments;
