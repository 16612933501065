import React from 'react';

function TheCompany(props) {
    return (
        <main id="main">
                <section id="call-to-action" className="call-to-action">
      <img src="assets/img/RHBPL-007.jpg" alt="" />
      <div className="container">
        <div className="row justify-content-center" >
          <div className="col-xl-10">
            <div className="text-center">
              <h3>The Company</h3>
              <p>The inspiring leadership at RHBPL has catapulted the company to eminence as one of India’s largest independently owned EPC groups. </p>
            </div>
          </div>
        </div>
      </div>
      
 </section>
 <section id="testimonials" className="testimonials">
      <div className="container section-title">
        <h2>Inspirational Leadership</h2>
        <p>The inspiring leadership at RHBPL has catapulted the company to eminence as one of India’s largest independently owned EPC groups.
           RHBPL’s success is enabled by its powerful execution capability, proprietary fleet of 5,000 machinery and vehicles and 
           its 3,000+ employees using cutting edge technology and innovation, such as Drone Monitoring, GPS Monitoring and Safety Management.</p>
      </div>

      <div className="container" >
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12 member" >
                 <div className="swiper">
              <div className="swiper-wrapper">

                <div className="swiper-slide">
                  <div className="testimonial-item">
                      
                      <div className="row">
                        <div className="col-lg-4 col-md-12" >
                          <div className="text-center">
                            <img src="assets/img/chairman-sir.jpg" className="testimonial-img flex-shrink-0 shadow" alt="" />
                            <div>
                              <h3>Dr. Ramraj Karsoliya</h3>
                              <h4 className="fw-bold">CEO </h4>
                              <p className="px-4">                               
                               B.Tech, M.Tech - IIT Kharagpur,<br />Ph.D. - NIT Bhopal                            
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-12" >
                          <div className="text-center">
                            <img src="assets/img/surabhi-mam.jpg" className="testimonial-img flex-shrink-0 shadow" alt="" />
                            <div>
                              <h3>Dr. Surabhi Karsoliya</h3>
                              <h4 className="fw-bold">Director</h4>
                              <p className="px-4">
                                B.Tech (Gold Medalist) NIT, MBA-IIM,<br /> Ph.D., Ex-Deloitte US
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-12" >
                          <div className="text-center">
                            <img src="assets/img/saurabh-sir.jpg" className="testimonial-img flex-shrink-0 shadow" alt="" />
                            <div>
                              <h3>Mr. Saurabh Karsoliya</h3>
                              <h4 className="fw-bold">Director</h4>
                              <p className="px-4">
                               B.Tech (Gold Medalist) – NIT,<br /> MBA - Standford University
                              </p>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                      
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-12" >
                        <div className="text-center">
                          <img src="assets/img/surabhi-mam.jpg" className="testimonial-img flex-shrink-0 shadow" alt="" />
                          <div>
                            <h3>SURABHI KARSOLIYA</h3>
                            <h4>Director</h4>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-9 col-md-12" >
                        <p>
                          <i className="bi bi-quote quote-icon-left"></i>
                          <span>Surabhi Karsoliya is a Double Gold Medalist in B Tech (Computer Science and Engineering) from National Institute of Technology(NIT)
                            securing a record GPA of 9.86/10.00. She completed her MBA from Indian Institute of Management (IIM) and worked in Deloitte US as a
                             Senior Consultant. Realizing her entrepreneurial calling, she left Deloitte and joined Technocrats Group of Institutions as Secretary
                              and Managing Director. She is also the founder and director of Ramraj Hitech Buildcon, Ramraj Technology Solutions and Techno 
                              Infotech Limited.</span>
                          <i className="bi bi-quote quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="swiper-pagination"></div>
            </div>
           </div>

            </div>  
      </div>
    </section>
    <section id="features" className="features">
      <div className="container">
        <div className="row gy-4 align-items-center features-item">
          <div className="col-lg-5 order-2 order-lg-1">
            <h3>RHBPL Journey</h3>
            <p>From humble beginnings in 2016 to a road focused EPC leader in 2018, RHBPL’s journey is characterized by hard work,
               respect for relationships, and a drive to excel in anything they take up. They have upheld the tradition of 
               giving their best to all projects since conception. RHBPL’s value-centric delivery, execution prowess and commitment 
               to innovation have set the stage for the next phase of growth.</p>
          </div>
          <div className="col-lg-7 order-1 order-lg-2 d-flex align-items-center" >
            <div className="image-stack">
              <img src="assets/img/rhb-01.jpg" alt="" className="stack-front" />
              <img src="assets/img/rhbpl-img01.jpg" alt="" className="stack-back" />
            </div>
          </div>
        </div>

        <div className="row gy-4 align-items-stretch justify-content-between features-item ">
          <div className="col-lg-6 d-flex align-items-center features-img-bg">
            <img src="assets/img/awards.jpg" className="img-fluid" alt="" />
          </div>
          <div className="col-lg-5 d-flex justify-content-center flex-column">
            <h3>Awards & Recognitions</h3>
            <p>RHBPL is proud to be recognized by global partners, industry bodies and publications.</p>
            <p>The company's track record in quality has been bolstered by its association with the best globally. The spate of awards 
              RHBPL has received is a testament to its quality work and exceptional industry reputation. RHBPL is thankful to its partners
               for conferring such high honors on the company and being a part of its journey.</p>
          </div>
        </div>
      </div>
    </section>
        </main>
    );
}

export default TheCompany;