import React, { useState, useEffect } from "react";
import axios from "axios";

const Home = () => {
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  async function fetchData() {
    try {
      const response = await axios.get(
        apiUrl+"/api/slider_sections/last-inserted-image"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchData1() {
    try {
      const response = await axios.get(
        apiUrl+"/api/about/get_aboutuscontent"
      );
      setData1(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchData2() {
    try {
      const response = await axios.get(
        apiUrl+"/api/backward-integrations"
      );
      // console.log(response.data);
      setData2(response.data.data2);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchData3() {
    try {
      const response = await axios.get(
        apiUrl+"/api/iconic-projects"
      );
      // console.log(response.data);
      setData3(response.data.data3);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    async function fetchDataAsync() {
      await Promise.all([
        fetchData(),
        fetchData1(),
        fetchData2(),
        fetchData3(),
      ]);
    }

    fetchDataAsync();
  }, []);

  function stripHtmlTags(htmlString) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
  
  return (
    <React.StrictMode>
      <div id="main">
        <section id="hero" className="hero">
          {data === null ? (
            <p>Loading...</p>
          ) : data.image_url ? (
            <img src={data.image_url} alt="Last Inserted" />
          ) : (
            <p>No image available.</p>
          )}
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <h2>
                  {data === null ? (
                    <p>Loading...</p>
                  ) : data.slidercaption ? (
                    data.slidercaption
                  ) : (
                    <p>No Contents Available.</p>
                  )}
                </h2>
              </div>
              <div className="col-lg-5 mt-5">
                <span className="btn btn-primary shadow red-bg fw-bold brite-btn">
                  WRITE TO RAMRAJ HITECH BUILDCON
                </span>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="about">
          <div className="container">
            <div className="row align-items-xl-center g-4 icon-boxes">
              <div className="col-md-4">
                <div className="icon-box text-center">
                  <i className="bi bi-distribute-horizontal"></i>
                  <h3>Highways</h3>
                  <p>
                    Ramraj Hitech Buildcon Private Limited (RHBPL) is a Civil
                    Engineering Construction.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="icon-box text-center">
                  <i className="bi bi-align-top"></i>
                  <h3>Bridges</h3>
                  <p>
                    Having the passion to reach at the top, the eyes are open
                    towards all the directions.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="icon-box text-center">
                  <i className="bi bi-buildings"></i>
                  <h3>Buildings</h3>
                  <p>
                    The building of the growth of the company is build entirely
                    on the segment of building.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="features">
          <div className="container section-title">
            <h2>About Ramraj Hitech Buildcon Private Limited</h2>
            <p className="mt-4">
              Ramraj Hitech Buildcon is an infrastructure development company
              focusing on developing quality infrastructure including highways,
              bridges, and utilities in India and in future abroad. The
              government of India in Union Budget 2017 has enhanced allocation
              for highways sector by 12% to INR 64,900 Crores.
            </p>
          </div>

          <div className="container">
            <div className="row align-items-stretch justify-content-between features-item ">
              <div className="col-lg-5 d-flex justify-content-center flex-column">
              {data1 === null ? (
                  <p>Loading...</p>
                ) : data1.aboutcontents ? (
                  <div className="p-6" dangerouslySetInnerHTML={{ __html: data1.aboutcontents }} />
                ) : (
                  <p>No Contents available.</p>
                )}
              </div>
              <div className="col-lg-6 d-flex align-items-center features-img-bg">
                {data1 === null ? (
                  <p>Loading...</p>
                ) : data1.aboutusimage ? (
                  <img
                    src={data1.aboutusimage}
                    className="img-fluid"
                    alt="About Ramraj Hitech Buildcon"
                  />
                ) : (
                  <p>No image available.</p>
                )}
              </div>
            </div>
          </div>
        </section>
        <section id="call-to-action" className="call-to-action">
          <img src="assets/img/RHBPL-007.jpg" alt="" />
          <div className="container position-relative">
            <div className="row gy-4">
              <div className="col-lg-6 col-md-6">
                <div className="card card-tran p-4 text-center w-100 h-100">
                  <h3 className="fw-bold red-color">Vision</h3>
                  <p className="black-color">
                    To deliver the best solutions to clients, provide complete
                    transparency to RHBPL stakeholders and broaden activity base
                    by diversifying into other infrastructure disciplines so as
                    to sustain a healthy growth rate.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="card card-tran  p-4 text-center w-100 h-100">
                  <h3 className="fw-bold red-color">Mission</h3>
                  <p className="black-color">
                    To create a fair and courteous environment for clients,
                    employees, vendors, as well as the society.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="about">
          <div className="container section-title">
            <h2>Backward Integration</h2>
            <p>Creating an ecosystem for self-sustainability</p>
          </div>

          <div className="container">
            <div className="row g-5 gy-5">
              {data2 !== null ? (
                data2.map((item) => (
                  <div key={item.id} className="col-lg-3 col-md-6">
                    <div className="card cards shadow-sm border-0 p-4 text-center">
                      <i className={item.b_name}></i>
                      <h6 className="mt-3">{item.b_description}</h6>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </section>
        <section id="call-to-action" className="call-to-action">
          <img src="assets/img/RHBPL-008.jpg" alt="" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="text-center">
                  <h3>Equipments</h3>
                  <p>
                    Major equipment’s owned by the company for timely completion
                    and for quality assurance of Projects
                  </p>
                  <a className="cta-btn" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="projects" className="portfolio">
          <div className="container section-title">
            <h2>Iconic Projects</h2>
            <p>Diversified Portfolio in Step with India's Growth</p>
          </div>
          <div className="container">
            <div className="row">
              {data3 !== null ? (
                data3.map((item) => (
                  <div className="col-lg-3 col-md-6" key={item.id}>
                    <div className="card cards white-bg shadow border-0 text-center box-h-400">
                      <h5 className="box-heading red-bg p-3 mb-4 text-white">
                        {item.projectname}
                      </h5>
                      <div className="p-3 fs-14" dangerouslySetInnerHTML={{ __html: item.project_description }} />
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </section>
        <section id="clients" className="team">
          <div className="container section-title">
            <h2>RHBPL Happy Clients</h2>
            <p>Don’t take our word for it – here’s what our clients say:</p>
          </div>

          <div className="container">
            <div className="row gy-5 justify-content-center">
              <div className="col-lg-2 col-md-2 member">
                <div className="card shadow border-0">
                  <img
                    src="assets/img/cropped-Morth-2.jpg"
                    className=""
                    alt="img-fluid"
                  />
                </div>
              </div>

              <div className="col-lg-2 col-md-2 member">
                <div className="card shadow border-0">
                  <img
                    src="assets/img/cropped-MPRDC-Recruitment-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="call-to-action" className="call-to-action">
          <img src="assets/img/RHBPL-006.jpg" alt="" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="text-center">
                  <h3>Growth Opportunities</h3>
                  <p className="mt-4">
                    People form RHBPL's foundation. Comprehensive benefits,
                    competitive remuneration and fast-tracked growth help
                    employees lead a fulfilling life. Join RHBPL's team of
                    experts to get a jump start on your growth journey.{" "}
                  </p>
                  <a className="cta-btn" href="#">
                    Join Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.StrictMode>
  );
};

export default Home;
